// 
// divider.scss
// Theme Component

.divider{
  height: 1px;
  background: var(--#{$prefix}black);
  display: block;
  width: 100%;
  margin: 0 auto;
}
.divider-light{
  height: 1px;
  background: var(--#{$prefix}white);
  display: block;
  width: 100%;
  margin: 0 auto;
}