// News Category colours
.news-cat-health-studies {
    color: #fff !important;
    background-color: RGBA(0, 175, 84, var(--bs-bg-opacity, 1)) !important;
}
.news-cat-interviews {
    color: #fff !important;
    background-color: RGBA(4, 114, 77, var(--bs-bg-opacity, 1)) !important;
}
.news-cat-opinions {
    color: #fff !important;
    background-color: RGBA(98, 124, 133, var(--bs-bg-opacity, 1)) !important;
}
.news-cat-politics-campaigns {
    color: #fff !important;
    background-color: RGBA(165, 36, 34, var(--bs-bg-opacity, 1)) !important;
}
.news-cat-potv-news {
    color: #fff !important;
    background-color: RGBA(0,115,48, var(--bs-bg-opacity, 1)) !important;
}
.news-cat-product {
    color: #fff !important;
    background-color: RGBA(53, 82, 74, var(--bs-bg-opacity, 1)) !important;
}
.news-cat-technology-culture {
    color: #fff !important;
    background-color: RGBA(98, 124, 133, var(--bs-bg-opacity, 1)) !important;
}
.news-cat-vaping-news {
    color: #fff !important;
    background-color: RGBA(192, 50, 33, var(--bs-bg-opacity, 1)) !important;
}
.news-cat-business-news {
    color: #fff !important;
    background-color: RGBA(23, 96, 135, var(--bs-bg-opacity, 1)) !important;
}

.navbar {
    background-color: #121212;
    .nav-link {
        color: #ffffff;
        text-transform: uppercase;
        &.active {
            color: #ffffff;
        }
    }
}

.navbar-top {
    .container {
        padding: 0;
    }
}

.mobile-top-banner {
    text-align:center;
    background-color: #121212;
    padding-bottom: 10px;
    margin-bottom: -1px;
    max-width: 100%;
}

#navbarCollapse {
    background-color: #121212;
}

header .navbar-nav .show > .nav-link,
header .navbar-nav .nav-link.active{
  // color: $navbar-light-active-color;
  &:before {
    content: "";
    width: 0px;
    height: 0px;
    background: none;
    margin: 0;
  }
}

.card{
    &.card-overlay-bottom{
      overflow: hidden;
      .text-white {
        text-shadow: 2px 2px 5px rgba(18, 18, 18,1);
      }
      &:before {
        content: "";
        position: absolute;
        height: 50%;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba($black, 1)));
        background-image: linear-gradient(180deg, transparent, rgba(18, 18, 18, 1));
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(18, 18, 18,1) 0%, rgba(18, 18, 18,0.6) 75%, rgba(255,255,255,0) 100%);
        transition: .4s;
        z-index: 1;
      }
    }
    .card-img-overlay{
      z-index: 2;
    }
  }
