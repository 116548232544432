// 
// overlay.scss
// Theme Component

// Bg Dark overlay
// Creates the "bg-dark-overlay-#" variant
@for $i from 2 through 9 {
  $bg-dark-overlay: ($i);
  .bg-dark-overlay-#{($i)} {
    position: relative;
    z-index: 99;
    &:before {
      content: " ";
      background: var(--#{$prefix}black);
      opacity: ($i * .1);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}